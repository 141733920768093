<template>
  <div
    class="bg-secondary group fixed right-0 top-[30%] z-20 hidden cursor-pointer flex-col items-center justify-center rounded-l-3xl px-10 py-6 text-center font-bold uppercase text-[1.125] text-white drop-shadow-lg xl:flex"
  >
    <icon
      icon="cloche-alerte"
      class="mb-2 h-16 w-16 transition-all group-hover:scale-125"
    ></icon>
    Créer<br />
    une alerte
  </div>
</template>
<script setup>
</script>
