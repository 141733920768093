<template>
  <div>
    <icon-defs></icon-defs>

    <sticky-alert @click="visible = true"></sticky-alert>

    <site-navbar></site-navbar>

    <client-only>
      <alert-modal @store="alertRedirect" v-model:visible="visible" />
      <reservation-modal />
      <ConfirmDialog group="confirmation">
        <template #message="slotProps">
          <div class="border-bottom-1 flex w-full flex-col items-center gap-5">
            <i style="font-size: 3rem" :class="slotProps.message.icon"></i>

            <p>{{ slotProps.message.message }}</p>
          </div>
        </template>
      </ConfirmDialog>
    </client-only>

    <slot></slot>

    <site-footer></site-footer>
  </div>
</template>

<script setup>
import Dialog from "primevue/dialog";

const router = useRouter();
const visible = ref(false);
const { $listen, $unListen } = useNuxtApp();
import { useAuthStore } from "~/store/auth";

const authStore = useAuthStore();

let interval = ref(null);

// Start interval when user is authenticated (authStore.isAuthenticated) for fetching notifications periodically

watch(
  () => authStore.isAuthenticated,
  (isAuthenticated) => {
    if (isAuthenticated) {
      interval.value = setInterval(() => {
        authStore.fetchNotifications();
      }, 30000);
    } else {
      clearInterval(interval.value);
    }
  }, // watch options
  { immediate: true }
);

// on mounted listen to event

useHead({
  title: "Funargos",
  viewport: "width=device-width, initial-scale=1, maximum-scale=1",
  charset: "utf-8",

  meta: [
    {
      name: "description",
      content: "Mise en relation des chauffeurs funéraires",
    },
  ],
});

const onOpenCreateAlert = () => {
  visible.value = true;
};

onMounted(() => {
  $listen("open-create-alert", onOpenCreateAlert);
});

onBeforeUnmount(() => {
  $unListen("open-create-alert", onOpenCreateAlert);
});

const alertRedirect = () => {
  visible.value = false;
  router.push({
    path: "/dashboard/mes-alertes",
  });
};
</script>
<style scoped>
.dialog {
  padding: 0 !important;
}
</style>
