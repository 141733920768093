<template>
  <div ref="el" class="max-lg:hidden">
    <ClientOnly>
      <section-wrapper class="bg-primary overflow-visible px-3">
        <section-container
          v-if="authStore.isAuthenticated"
          mobile-padding
          class="max-w-container flex items-center justify-between gap-4 py-3"
        >
          <div>
            <a
              class="text-normal mr-5 font-bold text-white"
              href="tel:+33767473005"
            >
              <icon icon="tel" class="mr-3 h-6 w-6"></icon> 07 67 47 30 05
            </a>
            <a href="mailto:contact@funargos.fr" class="text-normal text-white">
              <icon icon="enveloppe" class="mr-3 h-5 w-5"></icon>
              contact@funargos.fr
            </a>
          </div>
          <Popover class="relative">
            <PopoverButton class="py-2" v-if="authStore.company">
              <span class="mr-3 text-white">{{ authStore.company.name }}</span>
              <icon icon="profil" class="h-6 w-6 text-white"></icon>
            </PopoverButton>
            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="translate-y-1 opacity-0"
              enter-to-class="translate-y-0 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="translate-y-0 opacity-100"
              leave-to-class="translate-y-1 opacity-0"
            >
              <PopoverPanel
                v-slot="{ close }"
                class="text-neutral-light absolute z-10 flex min-w-[15rem] flex-col overflow-hidden rounded-3xl bg-white text-black drop-shadow-lg"
              >
                <NuxtLink
                  to="/dashboard"
                  @click="close()"
                  class="hover:bg-background-3 border-b px-5 py-3 text-[0.75rem]"
                >
                  <icon icon="profil" class="mr-3 h-4 w-4"></icon>
                  Mes informations
                </NuxtLink>

                <NuxtLink
                  to="/dashboard/mes-trajets-publies"
                  @click="close()"
                  class="hover:bg-background-3 border-b px-5 py-3 text-[0.75rem]"
                >
                  <icon icon="route" class="mr-3 h-4 w-4"></icon>
                  Mes trajets publiés
                </NuxtLink>

                <NuxtLink
                  to="/dashboard/mes-demandes-de-trajets"
                  @click="close()"
                  class="hover:bg-background-3 border-b px-5 py-3 text-[0.75rem]"
                >
                  <icon icon="route" class="mr-3 h-4 w-4"></icon>
                  Mes demandes de trajets
                </NuxtLink>

                <NuxtLink
                  to="/dashboard/mes-alertes"
                  @click="close()"
                  class="hover:bg-background-3 border-b px-5 py-3 text-[0.75rem]"
                >
                  <icon icon="cloche-alerte" class="mr-3 h-4 w-4"></icon>
                  Mes alertes
                </NuxtLink>

                <NuxtLink
                  @click="
                    authStore.logout();
                    close();
                  "
                  class="hover:bg-background-3 flex cursor-pointer items-center border-b px-5 py-3 text-[0.75rem]"
                >
                  <i class="pi pi-power-off mr-3"></i>
                  Se déconnecter
                </NuxtLink>
              </PopoverPanel>
            </transition>
          </Popover>
        </section-container>

        <section-container
          v-else
          mobile-padding
          class="max-w-container flex items-center justify-between py-3"
        >
          <div>
            <a
              class="mr-5 text-xl font-bold text-white"
              href="tel:+33767473005"
            >
              <icon icon="tel" class="mr-3 h-8 w-8"></icon> 07 67 47 30 05
            </a>
            <a
              href="mailto:contact@funargos.fr"
              class="text-xl font-bold text-white"
            >
              <icon icon="mail" class="mr-3 h-8 w-8"></icon> contact@funargos.fr
            </a>
          </div>
          <span class="flex gap-4">
            <NuxtLink
              class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
              to="/auth/login"
            >
              <rounded-button design="2" size="md">Se connecter</rounded-button>
            </NuxtLink>
            <NuxtLink
              class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
              to="/auth/register"
            >
              <rounded-button design="1" size="md">Inscription</rounded-button>
            </NuxtLink>
          </span>
        </section-container>
      </section-wrapper>
    </ClientOnly>

    <section-wrapper class="bg-white">
      <section-container
        mobile-padding
        class="max-w-container flex h-32 items-center max-xl:h-24"
      >
        <NuxtLink to="/">
          <img
            class="mr-10 max-xl:w-20"
            src="/images/logo-couleur.svg"
            alt="logo"
          />
        </NuxtLink>
        <div class="flex h-full items-center text-[0.938rem] font-medium">
          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            to="/"
            >Accueil
          </NuxtLink>
          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            to="/nos-services"
            >Nos services
          </NuxtLink>

          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            href="/notre-concept"
            >Notre concept
          </NuxtLink>
        </div>
        <div class="grow"></div>

        <div class="flex h-full items-center gap-10 font-medium">
          <NuxtLink
            class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
            to="/publier-un-trajet"
          >
            <icon icon="plus" class="text-secondary mr-3 h-6 w-6"></icon>
            Publier un trajet
          </NuxtLink>
          <NuxtLink
            class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
            to="/rechercher-un-trajet"
          >
            <icon icon="loupe" class="text-ternary mr-3 h-6 w-6"></icon>
            Rechercher un trajet
          </NuxtLink>
        </div>
      </section-container>
    </section-wrapper>
  </div>
  <div>
    <div class="h-[5rem] w-full" v-if="isMobileNavbar"></div>
    <Transition
      style="animation-duration: 300ms"
      :enter-active-class="
        !isMobileNavbar ? 'animate__animated animate__slideInDown' : ''
      "
      :leave-active-class="
        !isMobileNavbar ? 'animate__animated animate__slideOutUp' : ''
      "
    >
      <div
        v-if="shouldDisplayMobileNavbar"
        class="fixed top-0 z-30 flex w-full items-center justify-between bg-white px-5"
      >
        <NuxtLink to="/">
          <img
            class="mr-10 h-20 w-20"
            src="/images/logo-couleur.svg"
            alt="logo"
          />
        </NuxtLink>

        <div
          class="flex h-full items-center text-[0.938rem] font-medium max-[1080px]:hidden"
        >
          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            to="/"
            >Accueil
          </NuxtLink>
          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            to="/nos-services"
            >Nos services
          </NuxtLink>
          <NuxtLink
            class="hover:text-primary flex h-full items-center px-5 transition-colors"
            href="/notre-concept"
            >Notre concept
          </NuxtLink>
        </div>
        <div class="grow"></div>

        <div
          class="mr-10 flex h-full items-center gap-10 font-medium max-[1080px]:hidden"
        >
          <NuxtLink
            class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
            to="/publier-un-trajet"
          >
            <icon icon="plus" class="text-secondary mr-3 h-6 w-6"></icon>
            Publier un trajet
          </NuxtLink>
          <NuxtLink
            class="hover:text-primary flex h-full items-center font-semibold uppercase transition-colors"
            to="/rechercher-un-trajet"
          >
            <icon icon="loupe" class="text-ternary mr-3 h-6 w-6"></icon>
            Rechercher un trajet
          </NuxtLink>
        </div>

        <img
          class="cursor-pointer min-[1080px]:hidden"
          :src="menuOpen ? '/images/CLOSE.svg' : '/images/BURGER.svg'"
          @click="menuOpen = !menuOpen"
          alt="burger"
        />
      </div>
    </Transition>

    <Transition
      style="animation-duration: 300ms"
      enter-active-class="animate__animated animate__slideInDown"
      leave-active-class="animate__animated animate__slideOutUp"
    >
      <div
        v-if="menuOpen && shouldDisplayMobileNavbar"
        class="bg-primary fixed bottom-0 left-0 right-0 top-0 z-20 flex h-full w-full flex-col"
      >
        <div class="flex grow items-center justify-center">
          <div
            class="text-primary-lighter flex flex-col gap-2 text-center text-[1rem] font-semibold"
          >
            <NuxtLink
              @click="menuOpen = false"
              to="/"
              :class="`${
                currentRoute.name == 'index'
                  ? 'text-[1.65rem]'
                  : 'text-opacity-40'
              } text-white hover:text-white`"
              >Accueil
            </NuxtLink>
            <NuxtLink
              @click="menuOpen = false"
              to="/nos-services"
              :class="`${
                currentRoute.name == 'nos-services'
                  ? 'text-[1.65rem]'
                  : 'text-opacity-40'
              } text-white hover:text-white`"
              >Nos services
            </NuxtLink>
            <NuxtLink
              @click="menuOpen = false"
              to="/notre-concept"
              :class="`${
                currentRoute.name == 'notre-concept'
                  ? 'text-[1.65rem]'
                  : 'text-opacity-40'
              } text-white hover:text-white`"
              >Notre concept
            </NuxtLink>
            <div v-if="authStore.isAuthenticated">
              <NuxtLink
                @click="menuOpen = false"
                to="/dashboard"
                :class="`${
                  currentRoute.name == 'logout'
                    ? 'text-[1.65rem]'
                    : 'text-opacity-40'
                } text-white-400 hover:text-white`"
              >
                Tableau de bord
              </NuxtLink>
            </div>
            <div v-if="authStore.isAuthenticated">
              <NuxtLink
                @click="
                  authStore.logout();
                  menuOpen = false;
                "
                :class="`${
                  currentRoute.name == 'logout'
                    ? 'text-[1.65rem]'
                    : 'text-opacity-40'
                } text-red-400 hover:text-white`"
              >
                Se déconnecter
              </NuxtLink>
            </div>
            <div v-if="!authStore.isAuthenticated">
              <NuxtLink
                @click="menuOpen = false"
                to="/auth/login"
                :class="`${
                  currentRoute.name == 'logout'
                    ? 'text-[1.65rem]'
                    : 'text-opacity-40'
                } text-white-400 hover:text-white`"
              >
                Se connecter
              </NuxtLink>
            </div>
          </div>
        </div>

        <NuxtLink
          @click="menuOpen = false"
          to="/publier-un-trajet"
          class="bg-secondary hover:bg-secondary-light cursor-pointer px-5 py-6 text-center font-semibold uppercase text-white"
        >
          <icon icon="plus" class="mr-2 h-6 w-6"></icon>
          Publier un trajet
        </NuxtLink>
        <NuxtLink
          @click="menuOpen = false"
          to="/rechercher-un-trajet"
          class="bg-ternary hover:bg-ternary-light cursor-pointer px-5 py-6 text-center font-semibold uppercase text-white"
        >
          <icon icon="loupe" class="mr-2 h-6 w-6"></icon>
          Rechercher un trajet
        </NuxtLink>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { useWindowScroll } from "@vueuse/core";
import { useElementSize } from "@vueuse/core";
import { useMediaQuery } from "@vueuse/core";
import { useRouter } from "vue-router";
import { useAuthStore } from "~/store/auth";
import { ref, computed } from "vue";

const popoverOpen = ref(false);

const el = ref(null);
const { height } = useElementSize(el);
const menuOpen = ref(false);
const { y } = useWindowScroll();

const isMobileNavbar = useMediaQuery("(max-width: 1024px)");

const router = useRouter();

const currentRoute = router.currentRoute;

const authStore = useAuthStore();

const isOutOfScroll = computed(() => {
  return y.value > height.value;
});

const shouldDisplayMobileNavbar = computed(() => {
  return isOutOfScroll.value || isMobileNavbar.value;
});
</script>
